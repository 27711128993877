import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { oneOf } from "prop-types";
import { DrawerContent, DrawerOverlay, DrawerTransition } from "./components";

var Drawer = function Drawer(_ref) {
  var isOpen = _ref.isOpen,
      onClose = _ref.onClose,
      children = _ref.children,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "lg" : _ref$size,
      isFullHeight = _ref.isFullHeight,
      initialFocusRef = _ref.initialFocusRef,
      hideOverlay = _ref.hideOverlay,
      _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? "right" : _ref$placement;
  return jsx(DrawerTransition, {
    isOpen: isOpen,
    placement: placement,
    isFullHeight: isFullHeight
  }, function (_ref2) {
    var styles = _ref2.styles,
        transform = _ref2.transform,
        placements = _ref2.placements;
    return jsx(DrawerOverlay, {
      initialFocusRef: initialFocusRef,
      onDismiss: onClose,
      hideOverlay: hideOverlay,
      css: {
        opacity: styles.opacity
      }
    }, jsx(DrawerContent, {
      css: function css(theme) {
        return _extends({
          maxWidth: theme.sizes[size],
          position: "fixed",
          transform: transform(styles.offset)
        }, placements[placement]);
      }
    }, children));
  });
};

process.env.NODE_ENV !== "production" ? Drawer.propTypes = {
  size: oneOf(["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "6xl", "full"]),
  placement: oneOf(["top", "left", "right", "bottom"])
} : void 0;
export default Drawer;
export * from "./components";