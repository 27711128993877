import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import toaster from "toasted-notes";
import Alert, { AlertIcon, AlertTitle, AlertDescription } from "../Alert";
import ThemeProvider, { useTheme, UIModeProvider } from "../ThemeProvider";
import Box from "../Box";
import CloseButton from "../CloseButton";

var Toast = function Toast(_ref) {
  var status = _ref.status,
      variant = _ref.variant,
      id = _ref.id,
      title = _ref.title,
      isClosable = _ref.isClosable,
      onClose = _ref.onClose,
      description = _ref.description,
      props = _objectWithoutProperties(_ref, ["status", "variant", "id", "title", "isClosable", "onClose", "description"]);

  return jsx(Alert, _extends({
    status: status,
    variant: variant,
    id: id,
    textAlign: "left",
    boxShadow: "lg",
    rounded: "md",
    alignItems: "start",
    m: 2,
    pr: 7
  }, props), jsx(AlertIcon, null), jsx(Box, {
    flex: "1"
  }, title && jsx(AlertTitle, null, title), description && jsx(AlertDescription, null, description)), isClosable && jsx(CloseButton, {
    size: "sm",
    onClick: onClose,
    position: "absolute",
    right: "4px",
    top: "4px"
  }));
};

var ChakraProvider = function ChakraProvider(_ref2) {
  var theme = _ref2.theme,
      children = _ref2.children;
  return jsx(ThemeProvider, {
    theme: theme
  }, jsx(UIModeProvider, null, children));
};

export function useToast() {
  var theme = useTheme();

  function notify(_ref3) {
    var _ref3$position = _ref3.position,
        position = _ref3$position === void 0 ? "bottom" : _ref3$position,
        _ref3$duration = _ref3.duration,
        duration = _ref3$duration === void 0 ? 5000 : _ref3$duration,
        render = _ref3.render,
        title = _ref3.title,
        description = _ref3.description,
        status = _ref3.status,
        _ref3$variant = _ref3.variant,
        variant = _ref3$variant === void 0 ? "solid" : _ref3$variant,
        isClosable = _ref3.isClosable;
    var options = {
      position: position,
      duration: duration
    };

    if (render) {
      return toaster.notify(function (_ref4) {
        var onClose = _ref4.onClose,
            id = _ref4.id;
        return jsx(ChakraProvider, {
          theme: theme
        }, render({
          onClose: onClose,
          id: id
        }));
      }, options);
    }

    toaster.notify(function (_ref5) {
      var onClose = _ref5.onClose,
          id = _ref5.id;
      return jsx(ChakraProvider, {
        theme: theme
      }, jsx(Toast, {
        onClose: onClose,
        id: id,
        title: title,
        description: description,
        status: status,
        variant: variant,
        isClosable: isClosable
      }));
    }, options);
  }

  return notify;
}
export default useToast;