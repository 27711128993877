import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { ThemeContext } from "@emotion/core";
import { useContext } from "react";
import { TabContext } from ".";
import { useUIMode } from "../ThemeProvider";
export var baseProps = {
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.2s",
  _focus: {
    zIndex: "1",
    boxShadow: "outline"
  }
};
export var disabledProps = {
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  }
};

var lineStyle = function lineStyle(_ref) {
  var color = _ref.color,
      mode = _ref.mode;
  var _color = {
    light: "".concat(color, ".600"),
    dark: "".concat(color, ".300")
  };
  return {
    tabList: {
      borderBottom: "2px",
      borderColor: "inherit"
    },
    tab: {
      borderBottom: "2px",
      borderColor: "transparent",
      mb: "-2px",
      _selected: {
        color: _color[mode],
        borderColor: "currentColor"
      },
      _active: {
        bg: "gray.200"
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed"
      }
    }
  };
};

var containedStyle = function containedStyle(props) {
  return {
    tab: {
      _notFirstChild: {
        borderLeftWidth: 1
      },
      _selected: {
        bg: "gray.100"
      }
    },
    tabList: {
      bg: "#fff",
      borderWidth: "1px",
      rounded: "md",
      overflow: "hidden"
    }
  };
}; // TODO: Create new issue in @styled-system/css to allow custom alias


var enclosedStyle = function enclosedStyle(_ref2) {
  var color = _ref2.color,
      mode = _ref2.mode,
      theme = _ref2.theme;
  var _selectedColor = {
    light: "".concat(color, ".600"),
    dark: "".concat(color, ".300")
  };
  var _selectedBg = {
    light: "#fff",
    dark: theme.colors.gray[900]
  };
  return {
    tab: {
      roundedTop: "md",
      border: "1px",
      borderColor: "transparent",
      mb: "-1px",
      _selected: {
        color: _selectedColor[mode],
        borderColor: "inherit",
        borderBottomColor: _selectedBg[mode]
      }
    },
    tabList: {
      mb: "-1px",
      borderBottom: "1px",
      borderColor: "inherit"
    }
  };
};

var enclosedColoredStyle = function enclosedColoredStyle(_ref3) {
  var color = _ref3.color,
      mode = _ref3.mode;
  var bg = {
    light: "gray.50",
    dark: "whiteAlpha.50"
  };
  var _selectedColor = {
    light: "".concat(color, ".600"),
    dark: "".concat(color, ".300")
  };
  var _selectedBg = {
    light: "#fff",
    dark: "gray.900"
  };
  return {
    tab: {
      border: "1px",
      borderColor: "inherit",
      bg: bg[mode],
      mb: "-1px",
      _notLastChild: {
        mr: "-1px"
      },
      _selected: {
        bg: _selectedBg[mode],
        color: _selectedColor[mode],
        borderColor: "inherit",
        borderTopColor: "currentColor",
        borderBottomColor: "transparent"
      }
    },
    tabList: {
      mb: "-1px",
      borderBottom: "1px",
      borderColor: "inherit"
    }
  };
};

var softRoundedStyle = function softRoundedStyle(_ref4) {
  var color = _ref4.color;
  return {
    tab: {
      rounded: "full",
      fontWeight: "semibold",
      color: "gray.600",
      _selected: {
        color: "".concat(color, ".700"),
        bg: "".concat(color, ".100")
      }
    },
    tabList: {}
  };
};

var solidRoundedStyle = function solidRoundedStyle(_ref5) {
  var color = _ref5.color,
      mode = _ref5.mode;
  var _color = {
    light: "gray.600",
    dark: "inherit"
  };
  var _selectedBg = {
    light: "".concat(color, ".600"),
    dark: "".concat(color, ".300")
  };
  var _selectedColor = {
    light: "#fff",
    dark: "gray.900"
  };
  return {
    tab: {
      rounded: "full",
      fontWeight: "semibold",
      color: _color[mode],
      _selected: {
        color: _selectedColor[mode],
        bg: _selectedBg[mode]
      }
    },
    tabList: {}
  };
};

export var variantStyle = function variantStyle(props) {
  switch (props.variant) {
    case "line":
      return lineStyle(props);

    case "contained":
      return containedStyle(props);

    case "enclosed":
      return enclosedStyle(props);

    case "enclosed-colored":
      return enclosedColoredStyle(props);

    case "soft-rounded":
      return softRoundedStyle(props);

    case "solid-rounded":
      return solidRoundedStyle(props);

    default:
      break;
  }
}; // TO DO: Add support for vertical orientation

export var orientationStyle = function orientationStyle(_ref6) {
  var align = _ref6.align,
      orientation = _ref6.orientation;
  var alignments = {
    end: "flex-end",
    center: "center",
    start: "flex-start"
  };

  switch (orientation) {
    case "horizontal":
      return {
        alignItems: "center",
        justifyContent: alignments[align],
        maxWidth: "full"
      };

    case "vertical":
      return {
        flexDirection: "column"
      };

    default:
      return {};
  }
};
export var useTabStyle = function useTabStyle() {
  var theme = useContext(ThemeContext);

  var _useContext = useContext(TabContext),
      variant = _useContext.variant,
      color = _useContext.color,
      size = _useContext.size,
      isFitted = _useContext.isFitted;

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  return _extends({}, baseProps, {}, disabledProps, {}, theme.sizes.tab[size], {}, variantStyle({
    variant: variant,
    color: color,
    theme: theme,
    mode: mode
  }).tab, {}, isFitted && {
    flex: 1
  });
};
export var useTabListStyle = function useTabListStyle() {
  var theme = useContext(ThemeContext);

  var _useContext2 = useContext(TabContext),
      variant = _useContext2.variant,
      align = _useContext2.align,
      orientation = _useContext2.orientation;

  return _extends({}, variantStyle({
    variant: variant,
    theme: theme
  }).tabList, {}, orientationStyle({
    align: align,
    orientation: orientation
  }));
};