import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import propTypes from "prop-types";
import { useEffect, useState } from "react";
import Box from "../Box";
export var useHasImageLoaded = function useHasImageLoaded(_ref) {
  var src = _ref.src,
      onLoad = _ref.onLoad,
      onError = _ref.onError;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      hasLoaded = _useState2[0],
      setHasLoaded = _useState2[1];

  useEffect(function () {
    var img = new Image();
    img.src = src;

    img.onload = function () {
      setHasLoaded(true);
      onLoad && onLoad();
    };

    img.onerror = function () {
      setHasLoaded(false);
      onError && onError();
    };
  }, [src, onLoad, onError]);
  return hasLoaded;
};

var Img = function Img(_ref2) {
  var src = _ref2.src,
      onLoad = _ref2.onLoad,
      onError = _ref2.onError,
      fallbackSrc = _ref2.fallbackSrc,
      alt = _ref2.alt,
      props = _objectWithoutProperties(_ref2, ["src", "onLoad", "onError", "fallbackSrc", "alt"]);

  var hasLoaded = useHasImageLoaded({
    src: src,
    onLoad: onLoad,
    onError: onError
  });
  return jsx(Box, _extends({
    as: "img",
    src: hasLoaded ? src : fallbackSrc
  }, props));
};

process.env.NODE_ENV !== "production" ? Img.propTypes = {
  src: propTypes.string,
  fallbackSrc: propTypes.string,
  alt: propTypes.string.isRequired,
  onLoad: propTypes.func,
  onError: propTypes.func
} : void 0;
export default Img;