import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import propTypes from "prop-types";
import useAvatarStyle, { avatarSizes } from "./styles";
import { useHasImageLoaded } from "../Image";
import { useTheme, useUIMode } from "../ThemeProvider";
import Absolute from "../Absolute";
import Box from "../Box";
export var AvatarBadge = function AvatarBadge(props) {
  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var borderColor = {
    light: "white",
    dark: "gray.800"
  };
  return jsx(Absolute, _extends({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(25%, 25%)",
    bottom: "0",
    right: "0",
    border: "0.2em solid",
    borderColor: borderColor[mode],
    rounded: "full"
  }, props));
};

var getInitials = function getInitials(name) {
  var _name$split = name.split(" "),
      _name$split2 = _slicedToArray(_name$split, 2),
      firstName = _name$split2[0],
      lastName = _name$split2[1];

  if (firstName & lastName) {
    return "".concat(firstName.charAt(0)).concat(lastName.charAt(0));
  } else {
    return firstName.charAt(0);
  }
};

var AvatarName = function AvatarName(_ref) {
  var name = _ref.name,
      props = _objectWithoutProperties(_ref, ["name"]);

  return jsx(Box, _extends({
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "medium"
  }, props), name ? getInitials(name) : null);
};

var DefaultAvatar = function DefaultAvatar() {
  return jsx(Box, {
    size: "100%"
  }, jsx("svg", {
    fill: "#fff",
    viewBox: "0 0 128 128",
    role: "img"
  }, jsx("g", null, jsx("path", {
    d: "M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"
  }), jsx("path", {
    d: "M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"
  }))));
};

var Avatar = function Avatar(_ref2) {
  var size = _ref2.size,
      showBorder = _ref2.showBorder,
      name = _ref2.name,
      badge = _ref2.badge,
      src = _ref2.src,
      rest = _objectWithoutProperties(_ref2, ["size", "showBorder", "name", "badge", "src"]);

  var avatarProps = useAvatarStyle({
    name: name,
    size: size,
    showBorder: showBorder
  });
  var hasLoaded = useHasImageLoaded({
    src: src
  });
  var theme = useTheme();
  var sizeKey = avatarSizes[size];
  var _size = theme.sizes[sizeKey];

  var renderChildren = function renderChildren() {
    if (src && hasLoaded) {
      return jsx(Box, {
        as: "img",
        size: "100%",
        rounded: "full",
        objectFit: "cover",
        src: src,
        alt: name
      });
    }

    if (src && !hasLoaded) {
      if (name) {
        return jsx(AvatarName, {
          size: _size,
          name: name
        });
      } else {
        return jsx(DefaultAvatar, null);
      }
    }

    if (!src && name) {
      return jsx(AvatarName, {
        size: _size,
        name: name
      });
    }

    return jsx(DefaultAvatar, null);
  };

  return jsx(Box, _extends({
    fontSize: "calc(".concat(_size, " / 2.5)"),
    lineHeight: _size,
    verticalAlign: "bottom"
  }, avatarProps, rest), renderChildren(), badge);
};

Avatar.defaultProps = {
  size: "md"
};
process.env.NODE_ENV !== "production" ? Avatar.propTypes = {
  size: propTypes.oneOf(["xs", "sm", "md", "lg", "xl", "2xl"]),
  showBorder: propTypes.bool,
  name: propTypes.string.isRequired,
  badge: propTypes.node,
  src: propTypes.string
} : void 0;
export default Avatar;