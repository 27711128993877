import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Children, cloneElement } from "react";
import Avatar from "../Avatar";
import { avatarSizes } from "../Avatar/styles";
import Flex from "../Flex";
import { useUIMode } from "../ThemeProvider";
export var MoreIndicator = function MoreIndicator(_ref) {
  var size = _ref.size,
      label = _ref.label,
      props = _objectWithoutProperties(_ref, ["size", "label"]);

  var borderColor = {
    light: "#fff",
    dark: "gray.900"
  };
  var bg = {
    light: "gray.200",
    dark: "whiteAlpha.400"
  };

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  return jsx(Flex, _extends({
    bg: bg[mode],
    color: "inherit",
    rounded: "full",
    alignItems: "center",
    justifyContent: "center",
    border: "2px",
    borderColor: borderColor[mode],
    size: avatarSizes[size]
  }, props), label);
};

var AvatarGroup = function AvatarGroup(_ref2) {
  var size = _ref2.size,
      children = _ref2.children,
      _ref2$spacing = _ref2.spacing,
      spacing = _ref2$spacing === void 0 ? -3 : _ref2$spacing,
      rest = _objectWithoutProperties(_ref2, ["size", "children", "spacing"]);

  var count = Children.count(children);
  var clones = Children.map(children, function (child, index) {
    if (child.type !== Avatar) {
      return child.type === MoreIndicator ? cloneElement(child, {
        size: size,
        ml: spacing
      }) : child;
    }

    var isFirstAvatar = index === 0;
    return cloneElement(child, {
      ml: isFirstAvatar ? 0 : spacing,
      size: size,
      showBorder: true,
      css: {
        zIndex: count - index
      }
    });
  });
  return jsx(Flex, _extends({
    alignItems: "center"
  }, rest), clones);
};

export default AvatarGroup;