import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";

var useDisclosure = function useDisclosure(defaultIsOpen) {
  var _useState = useState(defaultIsOpen || false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var onClose = function onClose() {
    return setIsOpen(false);
  };

  var onOpen = function onOpen() {
    return setIsOpen(true);
  };

  var onToggle = function onToggle() {
    return setIsOpen(!isOpen);
  };

  return {
    isOpen: isOpen,
    onOpen: onOpen,
    onClose: onClose,
    onToggle: onToggle
  };
};

export default useDisclosure;