import _extends from "@babel/runtime/helpers/extends";
import { addOpacity, generateAlphaColors, get } from "../theme/colors.utils";
import { useTheme, useUIMode } from "../ThemeProvider";

var solidStyle = function solidStyle(_ref) {
  var colors = _ref.theme.colors,
      color = _ref.color;
  var darkModeBg = addOpacity(colors[color][500], 0.6);
  return {
    light: {
      bg: get(color, 500),
      color: "white"
    },
    dark: {
      bg: darkModeBg,
      color: "whiteAlpha.800"
    }
  };
};

var subtleStyle = function subtleStyle(_ref2) {
  var colors = _ref2.theme.colors,
      color = _ref2.color;
  var alphaColors = generateAlphaColors(colors[color][200]);
  var darkModeBg = alphaColors[400];
  return {
    light: {
      bg: get(color, 100),
      color: get(color, 800)
    },
    dark: {
      bg: darkModeBg,
      color: get(color, 200)
    }
  };
};

var outlineStyle = function outlineStyle(_ref3) {
  var colors = _ref3.theme.colors,
      color = _ref3.color;
  var darkModeColor = addOpacity(colors[color][200], 0.8);
  return {
    light: {
      boxShadow: "inset 0 0 0px 1px " + colors[color][500],
      color: get(color, 500)
    },
    dark: {
      boxShadow: "inset 0 0 0px 1px " + darkModeColor,
      color: darkModeColor
    }
  };
};

var variantProps = function variantProps(props) {
  var variant = props.variant,
      mode = props.mode;

  switch (variant) {
    case "solid":
      return solidStyle(props)[mode];

    case "subtle":
      return subtleStyle(props)[mode];

    case "outline":
      return outlineStyle(props)[mode];

    default:
      return {};
  }
};

var useBadgeStyle = function useBadgeStyle(props) {
  var theme = useTheme();

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var _props = _extends({}, props, {
    theme: theme,
    mode: mode
  });

  return variantProps(_props);
};

export default useBadgeStyle;