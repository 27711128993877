import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { oneOf } from "prop-types";
import Box from "../Box";
import useInputStyle from "../Input/styles";
import { useUIMode } from "../ThemeProvider";

var InputAddon = function InputAddon(_ref) {
  var _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? "left" : _ref$placement,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      props = _objectWithoutProperties(_ref, ["placement", "size"]);

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var bg = {
    dark: "whiteAlpha.300",
    light: "gray.100"
  };
  var _placement = {
    left: {
      mr: "-1px",
      roundedRight: 0,
      borderRightColor: "transparent"
    },
    right: {
      order: 1,
      roundedLeft: 0,
      borderLeftColor: "transparent"
    }
  };

  var styleProps = _extends({}, useInputStyle({
    size: size,
    variant: "outline"
  }), {
    flex: "0 0 auto",
    whiteSpace: "nowrap",
    bg: bg[mode]
  }, _placement[placement]);

  return jsx(Box, _extends({}, styleProps, props, {
    css: {
      "input:focus + &": {
        zIndex: -1
      }
    }
  }));
};

process.env.NODE_ENV !== "production" ? InputAddon.propTypes = {
  placement: oneOf(["left", "right"])
} : void 0;

var InputLeftAddon = function InputLeftAddon(props) {
  return jsx(InputAddon, _extends({
    placement: "left"
  }, props));
};

var InputRightAddon = function InputRightAddon(props) {
  return jsx(InputAddon, _extends({
    placement: "right"
  }, props));
};

export { InputLeftAddon, InputRightAddon };
export default InputAddon;