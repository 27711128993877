import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";
import { oneOf } from "prop-types";
import React from "react";
import Icon from "../Icon";
import Text from "../Text";
import Box from "../Box";
import Flex from "../Flex";

var StatLabel = function StatLabel(props) {
  return React.createElement(Text, _extends({
    fontWeight: "medium",
    fontSize: "sm"
  }, props));
};

var StatHelpText = function StatHelpText(props) {
  return React.createElement(Text, _extends({
    fontSize: "sm",
    opacity: "0.8",
    mb: 2
  }, props));
};

var StatNumber = function StatNumber(props) {
  return React.createElement(Text, _extends({
    fontSize: "2xl",
    verticalAlign: "baseline",
    fontWeight: "semibold"
  }, props));
};

var arrowOptions = {
  increase: {
    name: "triangle-up",
    color: "green.400"
  },
  decrease: {
    name: "triangle-down",
    color: "red.400"
  }
};

var StatArrow = function StatArrow(_ref) {
  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? "increase" : _ref$type,
      ariaLabel = _ref["aria-label"],
      rest = _objectWithoutProperties(_ref, ["type", "aria-label"]);

  return React.createElement(Icon, _extends({
    mr: 1,
    size: "14px",
    verticalAlign: "middle",
    "aria-label": ariaLabel
  }, arrowOptions[type], rest));
};

process.env.NODE_ENV !== "production" ? StatArrow.propTypes = {
  type: oneOf(["increase", "decrease"])
} : void 0;

var Stat = function Stat(props) {
  return React.createElement(Box, _extends({
    flex: "1",
    pr: 4,
    position: "relative"
  }, props));
};

var StatGroup = function StatGroup(props) {
  return React.createElement(Flex, _extends({
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "flex-start"
  }, props));
};

export { StatLabel, StatNumber, Stat, StatHelpText, StatArrow, StatGroup };