import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import Box from "../Box";
import { useUIMode } from "../ThemeProvider";

var Keyboard = function Keyboard(props) {
  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var bg = {
    light: "gray.100",
    dark: "whiteAlpha.50"
  };
  return jsx(Box, _extends({
    as: "kbd",
    bg: bg[mode],
    rounded: "md",
    border: "1px",
    borderColor: "inherit" // font="mono"
    ,
    fontSize: "0.85em",
    fontWeight: "bold",
    lineHeight: "normal",
    py: "0.2em",
    px: "0.4em",
    whiteSpace: "nowrap"
  }, props));
};

export default Keyboard;