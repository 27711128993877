import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { createContext, useContext } from "react";
import Box from "../Box";
export var useFormControl = function useFormControl(props) {
  var context = useFormControlContext();

  if (!context) {
    return props;
  }

  var keys = Object.keys(context);
  return keys.reduce(function (acc, prop) {
    acc[prop] = props[prop];

    if (context) {
      /** Giving precedence to `props` over `context` */
      if (props[prop] == null) {
        acc[prop] = context[prop];
      }
    }

    return acc;
  }, {});
};
var FormControlContext = createContext();
export var useFormControlContext = function useFormControlContext() {
  var context = useContext(FormControlContext);
  return context;
};

var FormControl = function FormControl(_ref) {
  var isInvalid = _ref.isInvalid,
      isRequired = _ref.isRequired,
      isDisabled = _ref.isDisabled,
      isReadOnly = _ref.isReadOnly,
      rest = _objectWithoutProperties(_ref, ["isInvalid", "isRequired", "isDisabled", "isReadOnly"]);

  var context = {
    isRequired: isRequired,
    isDisabled: isDisabled,
    isInvalid: isInvalid,
    isReadOnly: isReadOnly
  };
  return jsx(FormControlContext.Provider, {
    value: context
  }, jsx(Box, rest));
};

export default FormControl;