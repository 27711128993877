import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import Icon from "../Icon";
import PseudoBox from "../PseudoBox";
import { useUIMode } from "../ThemeProvider";

var baseProps = function baseProps(_ref) {
  var mode = _ref.mode;
  return {
    transition: "all 0.2s",
    flex: "0 0 auto",
    _hover: {
      bg: "blackAlpha.100"
    },
    _active: {
      bg: "blackAlpha.200"
    },
    _disabled: {
      cursor: "not-allowed"
    },
    _focus: {
      boxShadow: "outline"
    }
  };
};

var sizes = {
  lg: {
    button: "40px",
    icon: "16px"
  },
  md: {
    button: "32px",
    icon: "12px"
  },
  sm: {
    button: "24px",
    icon: "10px"
  }
};

var CloseButton = function CloseButton(_ref2) {
  var _ref2$size = _ref2.size,
      size = _ref2$size === void 0 ? "md" : _ref2$size,
      isDisabled = _ref2.isDisabled,
      color = _ref2.color,
      _ref2$icon = _ref2.icon,
      icon = _ref2$icon === void 0 ? "close" : _ref2$icon,
      _ref2$ariaLabel = _ref2["aria-label"],
      ariaLabel = _ref2$ariaLabel === void 0 ? "Close" : _ref2$ariaLabel,
      onClick = _ref2.onClick,
      rest = _objectWithoutProperties(_ref2, ["size", "isDisabled", "color", "icon", "aria-label", "onClick"]);

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var _size = sizes[size];
  return jsx(PseudoBox, _extends({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    "aria-disabled": isDisabled,
    disabled: isDisabled,
    onClick: onClick,
    "aria-label": ariaLabel,
    tabindex: "0",
    rounded: "md",
    as: "button",
    size: _size.button
  }, baseProps({
    mode: mode
  }), rest), jsx(Icon, {
    color: color,
    focusable: "false",
    name: icon,
    "aria-hidden": true,
    size: _size.icon
  }));
};

export default CloseButton;