import _extends from "@babel/runtime/helpers/extends";
import { get } from "../theme/colors.utils";
var baseProps = {
  userSelect: "none",
  border: "2px",
  rounded: "md",
  borderColor: "inherit",
  transition: "background-color 120ms, box-shadow 250ms"
};

var interactionProps = function interactionProps(_ref) {
  var color = _ref.color,
      mode = _ref.mode;
  var isDarkMode = mode === "dark";

  var _color = isDarkMode ? 200 : 500;

  return {
    color: "white",
    _checked: {
      bg: get(color, _color),
      borderColor: get(color, _color),
      color: isDarkMode ? "gray.900" : undefined
    },
    _checkedAndDisabled: {
      borderColor: isDarkMode ? "transparent" : "gray.200",
      bg: isDarkMode ? "whiteAlpha.300" : "gray.200",
      color: isDarkMode ? "whiteAlpha.500" : "gray.500"
    },
    _disabled: {
      bg: isDarkMode ? "whiteAlpha.100" : "gray.100",
      borderColor: isDarkMode ? "transparent" : "gray.100"
    },
    _focus: {
      boxShadow: "outline"
    },
    _invalid: {
      borderColor: isDarkMode ? "red.300" : "red.500"
    }
  };
};

var sizes = {
  lg: 5,
  md: 4,
  sm: 3
};

var checkboxStyles = function checkboxStyles(props) {
  return _extends({}, baseProps, {}, interactionProps(props), {
    size: sizes[props.size]
  });
};

export default checkboxStyles;