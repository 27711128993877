import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { useTheme, useUIMode } from "../ThemeProvider";

var outlinedStyle = function outlinedStyle(_ref) {
  var _ref$_focusBorderColo = _ref._focusBorderColor,
      _focusBorderColor = _ref$_focusBorderColo === void 0 ? "blue" : _ref$_focusBorderColo,
      colors = _ref.theme.colors,
      mode = _ref.mode;

  var bg = {
    light: "white",
    dark: "whiteAlpha.100"
  };
  var borderColor = {
    light: "inherit",
    dark: "whiteAlpha.50"
  };
  var hoverColor = {
    light: "gray.300",
    dark: "whiteAlpha.200"
  };
  var boxShadow = colors[_focusBorderColor][500];
  var invalidColor = {
    light: "red.500",
    dark: "red.300"
  };
  var invalidBoxShadow = {
    light: colors.red[500],
    dark: colors.red[300]
  };
  return _extends({}, readOnly, {
    border: "1px",
    borderColor: borderColor[mode],
    bg: bg[mode],
    _hover: {
      borderColor: hoverColor[mode]
    },
    _disabled: {
      opacity: "0.4",
      cursor: "not-allowed"
    },
    _focus: {
      borderColor: "".concat(_focusBorderColor, ".500"),
      boxShadow: "0 0 0 1px ".concat(boxShadow)
    },
    _invalid: {
      borderColor: invalidColor[mode],
      boxShadow: "0 0 0 1px ".concat(invalidBoxShadow[mode])
    }
  });
};

var readOnly = {
  _readOnly: {
    bg: "transparent",
    boxShadow: "none !important",
    userSelect: "all"
  }
};

var filledStyle = function filledStyle(_ref2) {
  var _focusBorderColor = _ref2._focusBorderColor,
      mode = _ref2.mode;
  var bg = {
    light: "gray.100",
    dark: "whiteAlpha.50"
  };
  var hoverColor = {
    light: "gray.200",
    dark: "whiteAlpha.100"
  };
  var invalidColor = {
    light: "red.500",
    dark: "red.300"
  };
  var focusColor = {
    light: "".concat(_focusBorderColor, ".500"),
    dark: "".concat(_focusBorderColor, ".300")
  };
  return _extends({}, readOnly, {
    border: "2px",
    borderColor: "transparent",
    bg: bg[mode],
    _hover: {
      bg: hoverColor[mode]
    },
    _disabled: {
      opacity: "0.4",
      cursor: "not-allowed"
    },
    _focus: {
      bg: "transparent",
      borderColor: focusColor[mode]
    },
    _invalid: {
      borderColor: invalidColor[mode]
    }
  });
};

var flushedStyle = function flushedStyle(_ref3) {
  var mode = _ref3.mode;
  var focusColor = {
    light: "blue.500",
    dark: "blue.300"
  };
  var errorColor = {
    light: "red.500",
    dark: "red.300"
  };
  return _extends({}, readOnly, {
    borderBottom: "2px",
    borderColor: "inherit",
    rounded: 0,
    px: undefined,
    bg: "transparent",
    _focus: {
      borderColor: focusColor[mode]
    },
    _invalid: {
      borderColor: errorColor[mode]
    }
  });
};

var unstyledStyle = {
  bg: "transparent",
  px: undefined,
  height: undefined
};

var variantProps = function variantProps(props) {
  switch (props.variant) {
    case "flushed":
      return flushedStyle(props);

    case "unstyled":
      return unstyledStyle;

    case "filled":
      return filledStyle(props);

    case "outline":
      return outlinedStyle(props);

    default:
      return {};
  }
};

var baseProps = {
  display: "flex",
  alignItems: "center",
  position: "relative",
  transition: "all 0.2s"
};
export var inputSizes = {
  lg: {
    fontSize: "lg",
    px: 4,
    height: "12",
    lineHeight: "24px",
    rounded: "md"
  },
  md: {
    fontSize: "md",
    px: 3,
    height: "10",
    lineHeight: "20px",
    rounded: "md"
  },
  sm: {
    fontSize: "sm",
    px: 2,
    height: "8",
    lineHeight: "16px",
    rounded: "sm"
  }
};

var sizeProps = function sizeProps(props) {
  return inputSizes[props.size];
};

var useInputStyle = function useInputStyle(props) {
  var theme = useTheme();

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var _props = _extends({}, props, {
    theme: theme,
    mode: mode
  });

  return _extends({
    width: props.isFullWidth ? "100%" : undefined
  }, baseProps, {}, sizeProps(_props), {}, variantProps(_props));
};

export default useInputStyle;