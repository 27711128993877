import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import Icon from "../Icon";
import { forwardRef } from "react";
import Input from "../Input";
import { useUIMode } from "../ThemeProvider";
import Box from "../Box";
import Absolute from "../Absolute";
var Select = forwardRef(function (_ref, ref) {
  var children = _ref.children,
      placeholder = _ref.placeholder,
      wrapperProps = _ref.wrapperProps,
      rest = _objectWithoutProperties(_ref, ["children", "placeholder", "wrapperProps"]);

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var themedColor = mode === "dark" ? "whiteAlpha.800" : "inherit";
  var opacity = rest.isReadOnly || rest.isDisabled ? 0.5 : 1;
  return jsx(Box, _extends({
    position: "relative",
    width: "100%",
    color: themedColor
  }, wrapperProps), jsx(Input, _extends({
    as: "select",
    appearance: "none",
    ref: ref,
    style: _extends({
      paddingRight: 24,
      paddingBottom: "unset"
    }, rest.style)
  }, rest), placeholder && jsx("option", {
    value: ""
  }, placeholder), children), jsx(Absolute, {
    display: "inline-flex",
    right: "8px",
    top: "50%",
    pointerEvents: "none",
    opacity: opacity,
    style: {
      zIndex: 2
    },
    transform: "translateY(-50%)"
  }, jsx(Icon, {
    name: "chevron-down",
    color: "currentColor",
    size: "18px"
  })));
});
export default Select;