import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import propTypes from "prop-types";
import Box from "../Box";
var sizes = {
  "2xl": "5xl",
  xl: "4xl",
  lg: "2xl",
  md: "xl",
  sm: "md",
  xs: "sm"
};

var Heading = function Heading(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      props = _objectWithoutProperties(_ref, ["size"]);

  return jsx(Box, _extends({
    fontSize: sizes[size],
    fontWeight: "bold"
  }, props));
};

process.env.NODE_ENV !== "production" ? Heading.propTypes = {
  size: propTypes.oneOf(["2xl", "xl", "lg", "md", "sm", "xs"])
} : void 0;
export default Heading;