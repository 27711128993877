import _defineProperty from "@babel/runtime/helpers/defineProperty";

/** @jsx jsx */
import styled from "@emotion/styled";
import css from "@styled-system/css";
import Box from "../Box";
import { tx } from "../Box/config"; // The selectors are based on WAI-ARIA speficiations

var hover = "&:hover";
var active = "&:active";
var focus = "&:focus";
var disabled = "&[aria-disabled=true], &:disabled, &:disabled:focus, &:disabled:hover, &:focus[aria-disabled=true], &:hover[aria-disabled=true]";
var checked = "&[aria-checked=true]";
var selected = "&[aria-selected=true]";
var invalid = "&[aria-invalid=true]";
var readOnly = "&[aria-readonly=true], &[readonly]";
var firstChild = "&:first-of-type";
var lastChild = "&:last-of-type";
var expanded = "&[aria-expanded=true]";
var grabbed = "&[aria-grabbed=true]";
var notFirstChild = "&:not(:first-of-type)";
var notLastChild = "&:not(:last-of-type)";
var PseudoBox = styled(Box)(function (_ref) {
  var _css;

  var _after = _ref._after,
      _focus = _ref._focus,
      _selected = _ref._selected,
      _focusWithin = _ref._focusWithin,
      _hover = _ref._hover,
      _invalid = _ref._invalid,
      _active = _ref._active,
      _disabled = _ref._disabled,
      _grabbed = _ref._grabbed,
      _expanded = _ref._expanded,
      _before = _ref._before,
      _readOnly = _ref._readOnly,
      _firstChild = _ref._firstChild,
      _notFirstChild = _ref._notFirstChild,
      _notLastChild = _ref._notLastChild,
      _lastChild = _ref._lastChild,
      _placeholder = _ref._placeholder,
      _checked = _ref._checked;
  return css((_css = {}, _defineProperty(_css, hover, tx(_hover)), _defineProperty(_css, focus, tx(_focus)), _defineProperty(_css, active, tx(_active)), _defineProperty(_css, disabled, tx(_disabled)), _defineProperty(_css, selected, tx(_selected)), _defineProperty(_css, invalid, tx(_invalid)), _defineProperty(_css, expanded, tx(_expanded)), _defineProperty(_css, grabbed, tx(_grabbed)), _defineProperty(_css, readOnly, tx(_readOnly)), _defineProperty(_css, firstChild, tx(_firstChild)), _defineProperty(_css, notFirstChild, tx(_notFirstChild)), _defineProperty(_css, notLastChild, tx(_notLastChild)), _defineProperty(_css, lastChild, tx(_lastChild)), _defineProperty(_css, checked, tx(_checked)), _defineProperty(_css, "&:before", tx(_before)), _defineProperty(_css, "&:after", tx(_after)), _defineProperty(_css, "&:focus-within", tx(_focusWithin)), _defineProperty(_css, "&::placeholder", _placeholder), _css));
});
export default PseudoBox;