import _extends from "@babel/runtime/helpers/extends";
import React, { forwardRef } from "react";
import Box from "../Box";
var Center = forwardRef(function (props, ref) {
  return React.createElement(Box, _extends({
    ref: ref,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  }, props));
});
export default Center;