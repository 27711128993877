import _extends from "@babel/runtime/helpers/extends";
import { system } from "styled-system";
export var config = {
  roundedTop: {
    properties: ["borderTopLeftRadius", "borderTopRightRadius"],
    scale: "radii"
  },
  roundedBottom: {
    properties: ["borderBottomLeftRadius", "borderBottomRightRadius"],
    scale: "radii"
  },
  roundedLeft: {
    properties: ["borderTopLeftRadius", "borderBottomLeftRadius"],
    scale: "radii"
  },
  roundedRight: {
    properties: ["borderTopRightRadius", "borderBottomRightRadius"],
    scale: "radii"
  },
  roundedTopRight: {
    property: "borderTopRightRadius",
    scale: "radii"
  },
  roundedTopLeft: {
    property: "borderTopLeftRadius",
    scale: "radii"
  },
  roundedBottomRight: {
    property: "borderBottomRightRadius",
    scale: "radii"
  },
  roundedBottomLeft: {
    property: "borderBottomLeftRadius",
    scale: "radii"
  },
  borderBottomColor: {
    property: "borderBottomColor",
    scale: "colors"
  },
  borderTopColor: {
    property: "borderTopColor",
    scale: "colors"
  },
  borderLeftColor: {
    property: "borderLeftColor",
    scale: "colors"
  },
  borderRightColor: {
    property: "borderRightColor",
    scale: "colors"
  },
  rounded: {
    property: "borderRadius",
    scale: "radii"
  },
  textDecoration: true,
  textTransform: true,
  overflowX: true,
  overflowY: true,
  animation: true,
  appearance: true,
  transform: true,
  transformOrigin: true,
  whiteSpace: true,
  userSelect: true,
  pointerEvents: true,
  boxSizing: true,
  cursor: true,
  resize: true,
  transition: true,
  fill: {
    property: "fill",
    scale: "colors"
  },
  stroke: {
    property: "stroke",
    scale: "colors"
  },
  objectFit: true,
  objectPosition: true,
  backgroundAttachment: true
};
config.bgAttachment = config.backgroundAttachment;
var extraConfig = system(config);
export default extraConfig; // Create an issue on @styled-system/css to allow custom alias to be passed to the `css` function
// In the meantime, let's transform the custom alias

var transformAlias = function transformAlias(prop, propValue) {
  var configKeys = Object.keys(config);
  var result = {};

  if (configKeys.includes(prop)) {
    var _config$prop = config[prop],
        properties = _config$prop.properties,
        property = _config$prop.property;

    if (properties) {
      properties.forEach(function (_cssProp) {
        return result[_cssProp] = propValue;
      });
    }

    if (property) {
      result[property] = propValue;
    }

    if (config[prop] == true) {
      result[prop] = propValue;
    }
  } else {
    result[prop] = propValue;
  }

  return result;
};

export var tx = function tx(props) {
  var result = {};

  for (var prop in props) {
    result = _extends({}, result, {}, transformAlias(prop, props[prop]));
  }

  return result;
};