import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import useBadgeStyle from "../Badge/styles";
import Icon from "../Icon";
import Box from "../Box";
import Center from "../Center";
import PseudoBox from "../PseudoBox";
import { createContext, useContext } from "react";
var tagSizes = {
  sm: {
    minHeight: "20px",
    minWidth: "20px",
    fontSize: "sm",
    px: "8px"
  },
  md: {
    minHeight: "24px",
    minWidth: "24px",
    fontSize: "sm",
    px: "8px"
  },
  lg: {
    minHeight: "32px",
    minWidth: "32px",
    px: "12px"
  }
};
var TagContext = createContext();

var useTagContext = function useTagContext() {
  return useContext(TagContext);
};

export var TagAddon = function TagAddon(_ref) {
  var placement = _ref.placement,
      props = _objectWithoutProperties(_ref, ["placement"]);

  var margin = {
    sm: "-4px",
    md: "-4px",
    lg: "-8px"
  };
  var sizes = {
    sm: "20px",
    md: "24px",
    lg: "24px"
  };

  var _useTagContext = useTagContext(),
      size = _useTagContext.size;

  var _placement = {
    left: {
      ml: margin[size]
    },
    right: {
      mr: margin[size]
    }
  };
  return jsx(Center, _extends({
    transition: "all 0.2s",
    size: sizes[size]
  }, _placement[placement], props));
};
export var TagCloseButton = function TagCloseButton(props) {
  var margin = {
    sm: "-8px",
    md: "-8px",
    lg: "-12px"
  };

  var _useTagContext2 = useTagContext(),
      size = _useTagContext2.size;

  return jsx(PseudoBox, _extends({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    as: "button",
    transition: "all 0.2s",
    mr: margin[size],
    width: tagSizes[size]["minWidth"],
    alignSelf: "stretch",
    rounded: "full",
    opacity: "0.5",
    _focus: {
      boxShadow: "outline",
      bg: "rgba(0, 0, 0, 0.14)"
    },
    _hover: {
      opacity: "0.8"
    },
    _active: {
      opacity: "1"
    }
  }, props), jsx(Icon, {
    size: "18px",
    name: "small-close",
    focusable: "false"
  }));
};
export var TagLabel = function TagLabel(props) {
  return jsx(Box, _extends({
    maxWidth: "160px",
    overflow: "hidden",
    wordBreak: "truncate",
    as: "span"
  }, props));
};

var Tag = function Tag(_ref2) {
  var _ref2$variant = _ref2.variant,
      variant = _ref2$variant === void 0 ? "subtle" : _ref2$variant,
      _ref2$size = _ref2.size,
      size = _ref2$size === void 0 ? "lg" : _ref2$size,
      color = _ref2.color,
      rest = _objectWithoutProperties(_ref2, ["variant", "size", "color"]);

  var tagStyleProps = useBadgeStyle({
    color: color,
    variant: variant
  });
  var sizeProps = tagSizes[size];
  return jsx(TagContext.Provider, {
    value: {
      size: size
    }
  }, jsx(PseudoBox, _extends({
    display: "inline-flex",
    alignItems: "center",
    minHeight: "24px",
    maxWidth: "100%",
    rounded: "md",
    fontWeight: "medium"
  }, sizeProps, tagStyleProps, rest)));
};

export default Tag;