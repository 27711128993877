import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { forwardRef } from "react";
import AnimateHeight from "react-animate-height";
import Box from "../Box";
var Collapse = forwardRef(function (_ref, ref) {
  var isOpen = _ref.isOpen,
      animateOpacity = _ref.animateOpacity,
      onAnimationStart = _ref.onAnimationStart,
      onAnimationEnd = _ref.onAnimationEnd,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 200 : _ref$duration,
      _ref$collapsedHeight = _ref.collapsedHeight,
      collapsedHeight = _ref$collapsedHeight === void 0 ? 0 : _ref$collapsedHeight,
      rest = _objectWithoutProperties(_ref, ["isOpen", "animateOpacity", "onAnimationStart", "onAnimationEnd", "duration", "collapsedHeight"]);

  return jsx(AnimateHeight, _extends({
    duration: duration,
    animateOpacity: animateOpacity,
    easing: "ease-in-out",
    height: isOpen ? "auto" : collapsedHeight
  }, {
    onAnimationStart: onAnimationStart,
    onAnimationEnd: onAnimationEnd
  }), jsx(Box, _extends({
    ref: ref
  }, rest)));
});
export default Collapse;