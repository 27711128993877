import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useUIMode } from "../ThemeProvider";
import { ModalTransition, ModalOverlay, ModalContent } from "./components";

var Modal = function Modal(_ref) {
  var isOpen = _ref.isOpen,
      onClose = _ref.onClose,
      children = _ref.children,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      isCentered = _ref.isCentered,
      initialFocusRef = _ref.initialFocusRef,
      rest = _objectWithoutProperties(_ref, ["isOpen", "onClose", "children", "size", "isCentered", "initialFocusRef"]);

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var centeredStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
  return jsx(ModalTransition, {
    isOpen: isOpen
  }, function (styles) {
    return jsx(ModalOverlay, {
      onClick: function onClick() {},
      initialFocusRef: initialFocusRef,
      css: [{
        opacity: styles.opacity
      }, isCentered && centeredStyle],
      onDismiss: onClose
    }, jsx(ModalContent, _extends({
      mode: mode,
      css: function css(_ref2) {
        var sizes = _ref2.sizes,
            radii = _ref2.radii;
        return _extends({}, !isCentered && {
          top: 40,
          margin: "0 auto"
        }, {
          maxWidth: sizes[size],
          borderRadius: radii.md,
          transform: "translate3d(0px, ".concat(styles.y, "px, 0px)")
        });
      }
    }, rest), children));
  });
};

export default Modal;
export * from "./components";