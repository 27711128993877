import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import propTypes from "prop-types";
import useBadgeStyle from "./styles";
import Box from "../Box";
import { forwardRef } from "react";
var Badge = forwardRef(function (_ref, ref) {
  var color = _ref.color,
      variant = _ref.variant,
      props = _objectWithoutProperties(_ref, ["color", "variant"]);

  var badgeStyleProps = useBadgeStyle({
    color: color,
    variant: variant
  });
  return jsx(Box, _extends({
    ref: ref,
    display: "inline-block",
    px: 1,
    textTransform: "uppercase",
    fontSize: "sm",
    borderRadius: "sm",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    verticalAlign: "middle"
  }, badgeStyleProps, props));
});
process.env.NODE_ENV !== "production" ? Badge.propTypes = {
  variant: propTypes.oneOf(["solid", "subtle", "outline"])
} : void 0;
Badge.defaultProps = {
  color: "gray",
  variant: "subtle"
};
export default Badge;