import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { node, oneOf, oneOfType, string } from "prop-types";
import { Children, cloneElement } from "react";
import Box from "../Box";
export var BreadcrumbItem = function BreadcrumbItem(_ref) {
  var isCurrent = _ref.isCurrent,
      separator = _ref.separator,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["isCurrent", "separator", "children"]);

  var currentProps = {
    "aria-current": "page",
    isUnstyled: true,
    as: "span"
  };
  return jsx(Box, _extends({
    as: "li",
    display: "inline-flex",
    alignItems: "center"
  }, rest), cloneElement(children, isCurrent && currentProps), !isCurrent && jsx(Box, {
    "aria-hidden": true,
    as: "span",
    verticalAlign: "middle",
    textAlign: "center",
    mx: "0.5em",
    fontSize: "1em",
    fontWeight: "semibold",
    opacity: "20%",
    children: separator
  }));
};
BreadcrumbItem.defaultProps = {
  separator: "/"
};
process.env.NODE_ENV !== "production" ? BreadcrumbItem.propTypes = {
  separator: oneOfType([string, node])
} : void 0;

var Breadcrumb = function Breadcrumb(_ref2) {
  var children = _ref2.children,
      separator = _ref2.separator,
      rest = _objectWithoutProperties(_ref2, ["children", "separator"]);

  return jsx(Box, _extends({
    as: "nav",
    "aria-label": "Breadcrumb"
  }, rest), jsx(Box, {
    as: "ol"
  }, Children.map(children, function (child) {
    return cloneElement(child, {
      separator: separator
    });
  })));
};

process.env.NODE_ENV !== "production" ? BreadcrumbItem.propTypes = {
  size: oneOf(["sm", "md", "lg"]),
  separator: oneOfType([string, node])
} : void 0;
export default Breadcrumb;