import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import PseudoBox from "../PseudoBox";

var useLinkStyle = function useLinkStyle(_ref) {
  var color = _ref.color,
      isUnstyled = _ref.isUnstyled;
  return _extends({
    transition: "all 0.15s ease-out",
    textDecoration: "none",
    color: "".concat(color, ".500"),
    cursor: "pointer",
    _focus: {
      boxShadow: "outline"
    },
    _disabled: {
      opacity: "40%",
      cursor: "not-allowed",
      textDecoration: "none"
    },
    _hover: {
      textDecoration: "underline"
    },
    _active: {
      opacity: "80%"
    }
  }, isUnstyled && {
    color: "inherit",
    cursor: "default",
    textDecoration: "none",
    _hover: {
      textDecoration: "none"
    }
  });
};

var Link = function Link(_ref2) {
  var isDisabled = _ref2.isDisabled,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? "blue" : _ref2$color,
      isUnstyled = _ref2.isUnstyled,
      onClick = _ref2.onClick,
      rest = _objectWithoutProperties(_ref2, ["isDisabled", "color", "isUnstyled", "onClick"]);

  var styledProps = useLinkStyle({
    color: color,
    isUnstyled: isUnstyled
  });
  return jsx(PseudoBox, _extends({
    as: "a",
    tabIndex: isDisabled ? -1 : undefined,
    "aria-disabled": isDisabled,
    borderRadius: "sm",
    onClick: isDisabled ? function (e) {
      return e.preventDefault();
    } : onClick
  }, styledProps, rest));
};

export default Link;