import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  animation: ", " 1s linear infinite;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  from { background-position: 1rem 0}\n  to { background-position: 0 0 }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/** @jsx jsx */
import { jsx, keyframes, css } from "@emotion/core";
import { number, oneOf, bool } from "prop-types";
import VisuallyHidden from "../VisuallyHidden";
import { useUIMode } from "../ThemeProvider";
import Box from "../Box";
import { generateStripe } from "../theme/colors.utils";
import { valueToPercent } from "../Slider/index";
var stripe = keyframes(_templateObject());
var stripeAnimation = css(_templateObject2(), stripe);
export var stripeStyle = generateStripe({});
var progressbarSizes = {
  lg: "1rem",
  md: "0.75rem",
  sm: "0.5rem"
};

var Progress = function Progress(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "blue" : _ref$color,
      _ref$value = _ref.value,
      value = _ref$value === void 0 ? 63 : _ref$value,
      _ref$min = _ref.min,
      min = _ref$min === void 0 ? 0 : _ref$min,
      _ref$max = _ref.max,
      max = _ref$max === void 0 ? 100 : _ref$max,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      hasStripe = _ref.hasStripe,
      isAnimated = _ref.isAnimated,
      rest = _objectWithoutProperties(_ref, ["color", "value", "min", "max", "size", "hasStripe", "isAnimated"]);

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var percent = valueToPercent(value, min, max);
  return jsx(Box, _extends({
    className: "progress",
    height: progressbarSizes[size],
    bg: mode === "dark" ? "whiteAlpha.300" : "gray.100",
    overflow: "hidden"
  }, rest), jsx(Box, {
    height: "100%",
    bg: "".concat(color, ".500"),
    "aria-valuemax": max,
    "aria-valuemin": min,
    "aria-valuenow": value,
    role: "progressbar",
    borderRadius: "full",
    transition: "all 0.3s",
    width: "".concat(percent, "%"),
    css: [hasStripe && stripeStyle, hasStripe && isAnimated && stripeAnimation]
  }, jsx(VisuallyHidden, null, "".concat(percent, "%"))));
};

process.env.NODE_ENV !== "production" ? Progress.propTypes = {
  size: oneOf(["md", "sm", "lg"]),
  value: number,
  hasStripe: bool,
  isAnimated: bool
} : void 0;
export default Progress;