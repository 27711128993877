import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 0;\n  margin-bottom: 0;\n  list-style: none !important;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { oneOf } from "prop-types";
import { Children, Fragment } from "react";
import Box from "../Box";
var unStyled = css(_templateObject());

var List = function List(_ref) {
  var inline = _ref.inline,
      ordered = _ref.ordered,
      type = _ref.type,
      children = _ref.children,
      styled = _ref.styled,
      showDivider = _ref.showDivider,
      spacing = _ref.spacing,
      rest = _objectWithoutProperties(_ref, ["inline", "ordered", "type", "children", "styled", "showDivider", "spacing"]);

  var setDisplay = function setDisplay() {
    if (inline) return "inline-block";
    if (ordered || styled) return "list-item";
    return "block";
  };

  return jsx(Box, _extends({
    as: ordered ? "ol" : "ul",
    pl: styled ? "40px" : rest.pl || 0,
    mb: styled ? 3 : rest.mb || 0
  }, styled === false && {
    css: unStyled
  }, rest), Children.map(children, function (child, index) {
    var isLastChild = children.length === index + 1;
    var spacingProps = inline && !isLastChild ? {
      mr: spacing
    } : {
      mb: spacing
    };
    return jsx(Fragment, null, jsx(Box, _extends({
      key: index,
      as: "li",
      px: inline ? 2 : 0,
      display: setDisplay()
    }, !showDivider && spacingProps), child), showDivider && !isLastChild && jsx(Box, {
      display: "inline",
      borderRight: "1px",
      borderColor: "gray.200",
      size: "100%",
      mx: spacing
    }));
  }));
};

process.env.NODE_ENV !== "production" ? List.propTypes = {
  type: oneOf(["bullet", "number", "none"])
} : void 0;
export default List;