import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Portal from "@reach/portal";
import { cloneElement, useRef, Children } from "react";
import { Manager, Popper, Reference } from "react-popper";
import { assignRef, genId } from "../utils";
import { PopoverTransition, popperStyle } from "../Popover/components";
import { useUIMode } from "../ThemeProvider";
import Box from "../Box";
import useDisclosure from "../useDisclosure";
var TooltipContent = styled(Box)(_templateObject(), popperStyle);

var Tooltip = function Tooltip(_ref) {
  var bg = _ref.bg,
      color = _ref.color,
      label = _ref.label,
      _ref$timeout = _ref.timeout,
      timeout = _ref$timeout === void 0 ? 100 : _ref$timeout,
      children = _ref.children,
      showArrow = _ref.showArrow,
      _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? "auto" : _ref$placement,
      closeOnClick = _ref.closeOnClick,
      defaultOpen = _ref.defaultOpen,
      controlledIsOpen = _ref.isOpen,
      onOpenChange = _ref.onOpenChange,
      rest = _objectWithoutProperties(_ref, ["bg", "color", "label", "timeout", "children", "showArrow", "placement", "closeOnClick", "defaultOpen", "isOpen", "onOpenChange"]);

  var _useDisclosure = useDisclosure(defaultOpen || false),
      isOpen = _useDisclosure.isOpen,
      onClose = _useDisclosure.onClose,
      onOpen = _useDisclosure.onOpen;

  var _useRef = useRef(controlledIsOpen != null),
      isControlled = _useRef.current;

  var openWithDelay = function openWithDelay() {
    setTimeout(onOpen, timeout);
  };

  var closeWithDelay = function closeWithDelay() {
    setTimeout(onClose, timeout);
  };

  var tooltipId = genId("tooltip");

  var handleOpen = function handleOpen() {
    !isControlled && openWithDelay();
    onOpenChange && onOpenChange();
  };

  var handleClose = function handleClose() {
    !isControlled && closeWithDelay();
    onOpenChange && onOpenChange();
  };

  var _isOpen = isControlled ? controlledIsOpen : isOpen;

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var _bg = mode === "dark" ? "gray.300" : "gray.700";

  var _color = mode === "dark" ? "gray.900" : "whiteAlpha.900";

  var bgColor = bg || _bg;
  var textColor = color || _color;
  return jsx(Manager, null, jsx(Reference, null, function (_ref2) {
    var referenceRef = _ref2.ref;
    return cloneElement(Children.only(children), {
      "aria-labelledby": tooltipId,
      ref: function ref(node) {
        assignRef(referenceRef, node);
      },
      onMouseOver: handleOpen,
      onMouseLeave: handleClose,
      onClick: function onClick(event) {
        closeOnClick && closeWithDelay();
        children.props.onClick && children.props.onClick(event);
      },
      onFocus: handleOpen,
      onBlur: handleClose
    });
  }), jsx(Popper, {
    placement: placement
  }, function (_ref3) {
    var popperRef = _ref3.ref,
        style = _ref3.style,
        arrowProps = _ref3.arrowProps,
        placement = _ref3.placement;
    return jsx(PopoverTransition, {
      duration: 50,
      isOpen: _isOpen
    }, function (styles) {
      return jsx(Portal, null, jsx(TooltipContent, _extends({
        ref: popperRef,
        px: "8px",
        py: "2px",
        id: tooltipId,
        role: "tooltip",
        bg: bgColor,
        borderRadius: "sm",
        fontWeight: "medium",
        color: textColor,
        css: _extends({}, style, {
          transform: "".concat(style.transform),
          opacity: styles.opacity
        }),
        "data-placement": placement,
        fontSize: "sm",
        boxShadow: "md",
        maxWidth: "320px"
      }, rest), label, showArrow && jsx(Box, {
        borderColor: bgColor,
        "data-arrow": "",
        ref: arrowProps.ref,
        style: arrowProps.style
      })));
    });
  }));
};

export default Tooltip;