import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  flex-shrink: 0;\n  backface-visibility: hidden;\n  &:not(:root) {\n    overflow: hidden;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/** @jsx jsx */
import { jsx, ThemeContext } from "@emotion/core";
import styled from "@emotion/styled";
import propTypes from "prop-types";
import { useContext } from "react";
import Box from "../Box";
var Svg = styled(Box)(_templateObject());

var Icon = function Icon(_ref) {
  var size = _ref.size,
      name = _ref.name,
      color = _ref.color,
      role = _ref.role,
      rest = _objectWithoutProperties(_ref, ["size", "name", "color", "role"]);

  var _useContext = useContext(ThemeContext),
      iconPaths = _useContext.icons;

  var iconFallback = iconPaths["question-outline"]; // Fallback in case you pass the wrong name

  var iconPath = iconPaths[name] == null ? iconFallback.path : iconPaths[name].path;
  var iconViewBox = (iconPaths[name] == null ? iconFallback.viewBox : iconPaths[name].viewBox) || "0 0 24 24";
  return jsx(Svg, _extends({
    as: "svg",
    size: size,
    color: color,
    display: "inline-block",
    viewBox: iconViewBox,
    role: role,
    verticalAlign: "middle"
  }, rest), iconPath);
};

Icon.defaultProps = {
  size: "1em",
  color: "currentColor"
};
process.env.NODE_ENV !== "production" ? Icon.propTypes = {
  role: propTypes.oneOf(["presentation", "img"])
} : void 0;
export default Icon;