export function genId(prefix) {
  return "".concat(prefix, "-").concat(Math.random().toString(32).substr(2, 8));
}
export var makeId = function makeId(id, index) {
  return "".concat(id, ":").concat(index);
};
export var assignRef = function assignRef(ref, value) {
  if (ref == null) return;

  if (typeof ref === "function") {
    ref(value);
  } else {
    try {
      ref.current = value;
    } catch (error) {
      throw new Error("Cannot assign value \"".concat(value, "\" to ref \"").concat(ref, "\""));
    }
  }
};
export var mergeRefs = function mergeRefs(refs, value) {
  refs.forEach(function (ref) {
    return assignRef(ref, value);
  });
};
var focusableElList = ["a[href]", "area[href]", "button:not([disabled])", "embed", "iframe", "input:not([disabled])", "object", "select:not([disabled])", "textarea:not([disabled])", "*[tabindex]:not([aria-disabled])", "*[contenteditable]"];
var focusableElSelector = focusableElList.join();
export function getFocusables(element) {
  var keyboardOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var focusableEls = Array.from(element.querySelectorAll(focusableElSelector)); // filter out elements with display: none

  focusableEls = focusableEls.filter(function (focusableEl) {
    return window.getComputedStyle(focusableEl).display !== "none";
  });

  if (keyboardOnly === true) {
    focusableEls = focusableEls.filter(function (focusableEl) {
      return focusableEl.getAttribute("tabindex") !== "-1";
    });
  }

  return focusableEls;
}