import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/** @jsx jsx */
import { jsx, ThemeContext } from "@emotion/core";
import { ThemeProvider as EThemeProvider } from "emotion-theming";
import { createContext, useContext, useState } from "react";
import useDarkMode from "use-dark-mode";
import theme from "../theme"; // This context handles the color mode (light or dark) of the UI

var UIModeContext = createContext();

var UIModeProvider = function UIModeProvider(_ref) {
  var overideValue = _ref.value,
      children = _ref.children;

  var _useState = useState(overideValue),
      _useState2 = _slicedToArray(_useState, 2),
      manualMode = _useState2[0],
      setManualMode = _useState2[1];

  var manualToggle = function manualToggle() {
    if (manualMode === "light") {
      setManualMode("dark");
    }

    if (manualMode === "dark") {
      setManualMode("light");
    }
  };

  var _useDarkMode = useDarkMode(false),
      value = _useDarkMode.value,
      toggle = _useDarkMode.toggle;

  var mode = value ? "light" : "dark";
  var childContext = overideValue ? {
    mode: manualMode,
    toggle: manualToggle
  } : {
    mode: mode,
    toggle: toggle
  };
  return jsx(UIModeContext.Provider, {
    value: childContext
  }, children);
};

var DarkMode = function DarkMode(props) {
  return jsx(UIModeProvider, _extends({
    value: "dark"
  }, props));
};

var LightMode = function LightMode(props) {
  return jsx(UIModeProvider, _extends({
    value: "light"
  }, props));
};

var useUIMode = function useUIMode() {
  var context = useContext(UIModeContext);

  if (context === undefined) {
    throw new Error("useUIMode must be used within a UIModeProvider");
  }

  return context;
};

var ThemeProvider = function ThemeProvider(_ref2) {
  var theme = _ref2.theme,
      children = _ref2.children;
  return jsx(EThemeProvider, {
    theme: theme
  }, children);
};

ThemeProvider.defaultProps = {
  theme: theme
};

var useTheme = function useTheme() {
  var theme = useContext(ThemeContext);

  if (theme === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return theme;
};

export default ThemeProvider;
export { UIModeProvider, useTheme, useUIMode, DarkMode, LightMode };