import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { forwardRef } from "react";
import { Transition } from "react-spring/renderprops";
import { useTheme, useUIMode } from "../ThemeProvider";
import Box from "../Box";
import Flex from "../Flex";
import Fixed from "../Fixed";

var ModalHeader = function ModalHeader(props) {
  return jsx(Box, _extends({
    px: 6,
    py: 4,
    as: "header",
    position: "relative",
    fontSize: "xl",
    fontWeight: "semibold"
  }, props));
};

var ModalFooter = function ModalFooter(props) {
  return jsx(Flex, _extends({
    px: 6,
    py: 4,
    as: "footer",
    justifyContent: "flex-end"
  }, props));
};

var ModalBody = function ModalBody(props) {
  return jsx(Box, _extends({
    px: 6,
    py: 2,
    flex: "1"
  }, props));
};

var ModalOverlay = forwardRef(function (props, ref) {
  return jsx(Fixed, _extends({
    as: DialogOverlay,
    zIndex: "1",
    bottom: "0",
    top: "0",
    left: "0",
    right: "0",
    overflowY: "auto",
    bg: "rgba(16,22,26,.7)",
    ref: ref
  }, props));
});
export var modalContentStyle = function modalContentStyle(_ref) {
  var mode = _ref.mode;
  var style = {
    light: {
      bg: "#fff",
      color: "inherit",
      boxShadow: "0 7px 14px 0 rgba(0,0,0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)"
    },
    dark: {
      bg: "gray.700",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px"
    }
  };
  return style[mode];
};
var ModalContent = forwardRef(function (props, ref) {
  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var theme = useTheme();
  var styleProps = modalContentStyle({
    mode: mode,
    theme: theme
  });
  return jsx(Box, _extends({
    as: DialogContent,
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    ref: ref
  }, styleProps, props));
});

var ModalTransition = function ModalTransition(_ref2) {
  var isOpen = _ref2.isOpen,
      _ref2$duration = _ref2.duration,
      duration = _ref2$duration === void 0 ? 150 : _ref2$duration,
      children = _ref2.children;
  return jsx(Transition, {
    items: isOpen,
    from: {
      opacity: 0,
      y: 10
    },
    enter: {
      opacity: 1,
      y: 0
    },
    leave: {
      opacity: 0,
      y: -10
    },
    config: {
      duration: duration
    }
  }, function (isOpen) {
    return isOpen && function (styles) {
      return children(styles);
    };
  });
};

export { ModalHeader, ModalTransition, ModalFooter, ModalBody, ModalOverlay, ModalContent };