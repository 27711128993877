import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  animation: ", " ", " linear infinite;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import Box from "../Box";
import Icon from "../Icon";
import { forwardRef } from "react";
import VisuallyHidden from "../VisuallyHidden";
var spin = keyframes(_templateObject());
var sizes = {
  xs: "0.75rem",
  sm: "1rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "3rem"
};
var Spinner = forwardRef(function (_ref, ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      _ref$loadingText = _ref.loadingText,
      loadingText = _ref$loadingText === void 0 ? "Loading..." : _ref$loadingText,
      _ref$thickness = _ref.thickness,
      thickness = _ref$thickness === void 0 ? "2px" : _ref$thickness,
      _ref$speed = _ref.speed,
      speed = _ref$speed === void 0 ? "0.45s" : _ref$speed,
      props = _objectWithoutProperties(_ref, ["size", "loadingText", "thickness", "speed"]);

  var _size = sizes[size] || size;

  return jsx(Box, _extends({
    ref: ref,
    display: "inline-block",
    border: "".concat(thickness, " solid"),
    borderColor: "currentColor",
    borderBottomColor: "transparent",
    borderLeftColor: "transparent",
    rounded: "full",
    animation: "".concat(spin, " ").concat(speed, " linear infinite"),
    size: _size
  }, props), loadingText && jsx(VisuallyHidden, null, loadingText));
});

var spinCSS = function spinCSS(speed) {
  return css(_templateObject2(), spin, speed || "1s");
};

export default Spinner;
/* Alt Spinner, just a lil more fancy :) */

export var Spinner2 = function Spinner2(_ref2) {
  var _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? "rgba(164, 164, 164, 1)" : _ref2$color,
      _ref2$size = _ref2.size,
      size = _ref2$size === void 0 ? "24px" : _ref2$size,
      speed = _ref2.speed;
  return jsx(Box, {
    display: "inline-block"
  }, jsx(Icon, {
    color: color,
    name: "spinner",
    size: size,
    css: spinCSS(speed)
  }));
};