import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import propTypes from "prop-types";
import { forwardRef } from "react";
import { useFormControl } from "../FormControl";
import PseudoBox from "../PseudoBox";
import useInputStyle from "./styles";
var Input = forwardRef(function (props, ref) {
  var type = props.type,
      size = props.size,
      variant = props.variant,
      as = props.as,
      ariaLabel = props["aria-label"],
      ariaDescribedby = props["aria-describedby"],
      isReadOnly = props.isReadOnly,
      isFullWidth = props.isFullWidth,
      isDisabled = props.isDisabled,
      isInvalid = props.isInvalid,
      isRequired = props.isRequired,
      _focusBorderColor = props._focusBorderColor,
      rest = _objectWithoutProperties(props, ["type", "size", "variant", "as", "aria-label", "aria-describedby", "isReadOnly", "isFullWidth", "isDisabled", "isInvalid", "isRequired", "_focusBorderColor"]);

  var inputStyleProps = useInputStyle(props);
  var formControl = useFormControl(props);
  return jsx(PseudoBox, _extends({
    ref: ref,
    as: as,
    type: type,
    name: name,
    readOnly: formControl.isReadOnly,
    disabled: formControl.isDisabled,
    "aria-label": ariaLabel,
    "aria-invalid": formControl.isInvalid,
    "aria-required": formControl.isRequired,
    "aria-disabled": formControl.isDisabled,
    "aria-describedby": ariaDescribedby
  }, inputStyleProps, rest));
});
Input.defaultProps = {
  size: "md",
  as: "input",
  variant: "outline",
  isFullWidth: true,
  _focusBorderColor: "blue"
};
process.env.NODE_ENV !== "production" ? Input.propTypes = {
  size: propTypes.oneOf(["md", "sm", "lg"]),
  type: propTypes.oneOf(["text", "email", "number", "password", "search"]),
  variant: propTypes.oneOf(["outline", "unstyled", "flushed", "filled"])
} : void 0;
export default Input;