import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  > [data-arrow] {\n    width: 0;\n    height: 0;\n    border-style: solid;\n    position: absolute;\n    margin: 5px;\n  }\n\n  &[data-placement^=\"top\"] {\n    margin-bottom: 5px;\n    transform-origin: bottom center;\n  }\n\n  &[data-placement^=\"top\"] > [data-arrow] {\n    border-width: 5px 5px 0 5px;\n    border-left-color: transparent;\n    border-right-color: transparent;\n    border-bottom-color: transparent;\n    bottom: -5px;\n    left: calc(50% - 5px);\n    margin-top: 0;\n    margin-bottom: 0;\n    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);\n  }\n\n  &[data-placement^=\"bottom\"] {\n    margin-top: 5px;\n    transform-origin: top center;\n  }\n\n  &[data-placement^=\"bottom\"] > [data-arrow] {\n    border-width: 0 5px 5px 5px;\n    border-left-color: transparent;\n    border-right-color: transparent;\n    border-top-color: transparent;\n    top: -5px;\n    left: calc(50% - 5px);\n    margin-top: 0;\n    margin-bottom: 0;\n    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);\n  }\n\n  &[data-placement^=\"right\"] {\n    margin-left: 5px;\n    transform-origin: left center;\n  }\n\n  &[data-placement^=\"right\"] > [data-arrow] {\n    border-width: 5px 5px 5px 0;\n    border-left-color: transparent;\n    border-top-color: transparent;\n    border-bottom-color: transparent;\n    left: -5px;\n    top: calc(50% - 5px);\n    margin-left: 0;\n    margin-right: 0;\n    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);\n  }\n\n  &[data-placement^=\"left\"] {\n    margin-right: 5px;\n    transform-origin: right center;\n  }\n\n  &[data-placement^=\"left\"] > [data-arrow] {\n    border-width: 5px 0 5px 5px;\n    border-top-color: transparent;\n    border-right-color: transparent;\n    border-bottom-color: transparent;\n    right: -5px;\n    top: calc(50% - 5px);\n    margin-left: 0;\n    margin-right: 0;\n    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { forwardRef } from "react";
import { Transition } from "react-spring/renderprops";
import CloseButton from "../CloseButton";
import Box from "../Box";
export var PopoverTransition = function PopoverTransition(_ref) {
  var isOpen = _ref.isOpen,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 200 : _ref$duration,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["isOpen", "duration", "children"]);

  return jsx(Transition, _extends({
    items: isOpen,
    from: {
      opacity: 0.01,
      scale: 0.75
    },
    enter: {
      opacity: 1,
      scale: 1
    },
    leave: {
      opacity: 0,
      scale: 0.75
    },
    config: _extends({
      duration: duration
    }, rest.config)
  }, rest), function (isOpen) {
    return isOpen && function (style) {
      return children(style);
    };
  });
};
export var popperStyle = css(_templateObject());
export var PopoverContent = forwardRef(function (_ref2, ref) {
  var css = _ref2.css,
      props = _objectWithoutProperties(_ref2, ["css"]);

  return jsx(Box, _extends({
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    ref: ref,
    css: [popperStyle, css]
  }, props));
});
export var PopoverCloseButton = function PopoverCloseButton(_ref3) {
  var onClick = _ref3.onClick,
      props = _objectWithoutProperties(_ref3, ["onClick"]);

  return jsx(CloseButton, _extends({
    size: "6",
    onClick: onClick,
    position: "absolute",
    rounded: "md",
    top: "12px",
    right: "12px",
    p: "6px"
  }, props));
};
export var PopoverHeader = function PopoverHeader(props) {
  return jsx(Box, _extends({
    px: "12px",
    boxShadow: "bottom",
    py: "8px",
    as: "header"
  }, props));
};
export var PopoverFooter = function PopoverFooter(props) {
  return jsx(Box, _extends({
    px: "12px",
    boxShadow: "top",
    py: "8px",
    as: "footer"
  }, props));
};
export var PopoverBody = function PopoverBody(_ref4) {
  var isScrollable = _ref4.isScrollable,
      props = _objectWithoutProperties(_ref4, ["isScrollable"]);

  return jsx(Box, _extends({
    flex: "1",
    px: "12px",
    py: "8px"
  }, isScrollable && {
    overflow: "hidden auto"
  }, props));
};