import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { forwardRef } from "react";
import ControlBox from "../ControlBox";
import { useTheme, useUIMode } from "../ThemeProvider";
import VisuallyHidden from "../VisuallyHidden";
import Box from "../Box";
var Switch = forwardRef(function (_ref, ref) {
  var id = _ref.id,
      name = _ref.name,
      value = _ref.value,
      ariaLabel = _ref["aria-label"],
      ariaLabelledBy = _ref["aria-labelledby"],
      color = _ref.color,
      defaultChecked = _ref.defaultChecked,
      isChecked = _ref.isChecked,
      size = _ref.size,
      isDisabled = _ref.isDisabled,
      isInvalid = _ref.isInvalid,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      onFocus = _ref.onFocus,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["id", "name", "value", "aria-label", "aria-labelledby", "color", "defaultChecked", "isChecked", "size", "isDisabled", "isInvalid", "onChange", "onBlur", "onFocus", "children"]);

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var theme = useTheme();
  var _theme$sizes$switch$s = theme.sizes.switch[size],
      width = _theme$sizes$switch$s.width,
      height = _theme$sizes$switch$s.height;
  var stylesProps = {
    rounded: "full",
    justifyContent: "flex-start",
    width: width,
    height: height,
    bg: mode === "dark" ? "whiteAlpha.400" : "gray.300",
    boxSizing: "content-box",
    p: "2px",
    _checked: {
      bg: "".concat(color, ".500")
    },
    _child: {
      transform: "translateX(0)"
    },
    _checkedAndChild: {
      transform: "translateX(calc(".concat(width, " - ").concat(height, "))")
    },
    _focus: {
      boxShadow: "outline"
    },
    _disabled: {
      opacity: 0.5
    }
  };
  return jsx(Box, _extends({
    as: "label",
    display: "inline-block",
    verticalAlign: "middle"
  }, rest), jsx(VisuallyHidden, {
    as: "input",
    type: "checkbox",
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    id: id,
    ref: ref,
    name: name,
    value: value,
    "aria-invalid": isInvalid,
    defaultChecked: defaultChecked,
    onChange: onChange,
    onBlur: onBlur,
    onFocus: onFocus,
    checked: isChecked,
    disabled: isDisabled
  }), jsx(ControlBox, stylesProps, jsx(Box, {
    bg: "white",
    transition: "transform 250ms",
    rounded: "full",
    size: height
  })));
});
Switch.defaultProps = {
  color: "blue",
  size: "md"
};
export default Switch;