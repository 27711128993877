import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import propTypes from "prop-types";
import { Children, cloneElement } from "react";
import Box from "../Box";

var ButtonGroup = function ButtonGroup(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "gray" : _ref$color,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "solid" : _ref$variant,
      isAttached = _ref.isAttached,
      _ref$spacing = _ref.spacing,
      spacing = _ref$spacing === void 0 ? 2 : _ref$spacing,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["size", "color", "variant", "isAttached", "spacing", "children"]);

  var clones = Children.map(children, function (child, index) {
    var isFirst = index === 0;
    var isLast = index === Children.count(children) - 1;
    return cloneElement(child, _extends({
      size: size || child.props.size,
      color: child.props.color || color,
      variant: child.props.variant || variant,
      _focus: {
        boxShadow: "outline",
        zIndex: 1
      }
    }, !isLast && !isAttached && {
      mr: spacing
    }, {}, isFirst && isAttached && {
      roundedRight: 0
    }, {}, isLast && isAttached && {
      roundedLeft: 0
    }, {}, !isFirst && !isLast && isAttached && {
      rounded: 0
    }));
  });
  return jsx(Box, _extends({
    display: "inline-block"
  }, rest), clones);
};

process.env.NODE_ENV !== "production" ? ButtonGroup.propTypes = {
  size: propTypes.oneOf(["sm", "md", "lg"]),
  color: propTypes.string,

  /**
   * If `true`, the borderRadius of button that are direct children will be altered
   * to look flushed together
   * */
  isAttached: propTypes.bool,
  spacing: propTypes.oneOfType([propTypes.string, propTypes.number]),
  children: propTypes.node.isRequired
} : void 0;
export default ButtonGroup;