import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import ReachAlert from "@reach/alert";
import { oneOf } from "prop-types";
import { createContext, useContext } from "react";
import Box from "../Box";
import Icon from "../Icon";
import { generateStripe } from "../theme/colors.utils";
import useAlertStyle, { useIconStyle } from "./styles";

var AlertTitle = function AlertTitle(props) {
  return jsx(Box, _extends({
    fontWeight: "bold",
    lineHeight: "normal"
  }, props));
};

var AlertDescription = function AlertDescription(props) {
  return jsx(Box, props);
};

var AlertContext = createContext();
var statusIcons = {
  info: "info",
  warning: "warning-2",
  success: "check-circle",
  error: "warning"
};
var statusColors = {
  error: "red",
  warning: "orange",
  info: "blue",
  success: "green"
};

var AlertIcon = function AlertIcon(props) {
  var _useContext = useContext(AlertContext),
      status = _useContext.status,
      variant = _useContext.variant;

  var iconStyleProps = useIconStyle({
    variant: variant,
    color: statusColors[status]
  });
  return jsx(Icon, _extends({
    mt: "2px",
    mr: 3,
    size: 5,
    name: statusIcons[status]
  }, iconStyleProps, props));
};

var Alert = function Alert(_ref) {
  var _ref$status = _ref.status,
      status = _ref$status === void 0 ? "info" : _ref$status,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "subtle" : _ref$variant,
      hasStripe = _ref.hasStripe,
      rest = _objectWithoutProperties(_ref, ["status", "variant", "hasStripe"]);

  var alertStyleProps = useAlertStyle({
    variant: variant,
    color: statusColors[status]
  });
  var stripeStyle = generateStripe({
    size: "8rem",
    color: "rgba(255, 255, 255, 0.05)"
  });
  var context = {
    status: status,
    variant: variant
  };
  return jsx(AlertContext.Provider, {
    value: context
  }, jsx(Box, _extends({
    as: ReachAlert,
    css: hasStripe && stripeStyle
  }, alertStyleProps, rest)));
};

process.env.NODE_ENV !== "production" ? Alert.propTypes = {
  /**
   * The status of the alert
   */
  status: oneOf(["error", "success", "warning", "info"]),

  /**
   * The variant of the alert style to use.
   */
  variant: oneOf(["subtle", "solid", "left-accent", "top-accent", "card"])
} : void 0;
export default Alert;
export { AlertTitle, AlertDescription, AlertIcon };