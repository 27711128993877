import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { colorEmphasis } from "../theme/colors.utils";
import { useTheme, useUIMode } from "../ThemeProvider";

var leftAccent = function leftAccent(props) {
  var color = props.color;
  return {
    light: _extends({}, subtle(props).light, {
      borderLeft: "4px",
      borderColor: "".concat(color, ".500")
    }),
    dark: _extends({}, subtle(props).dark, {
      borderLeft: "4px",
      borderColor: "".concat(color, ".200")
    })
  };
};

var topAccent = function topAccent(props) {
  var color = props.color;
  return {
    light: _extends({}, subtle(props).light, {
      borderTop: "4px",
      borderColor: "".concat(color, ".500")
    }),
    dark: _extends({}, subtle(props).dark, {
      borderTop: "4px",
      borderColor: "".concat(color, ".200")
    })
  };
};

var solid = function solid(_ref) {
  var color = _ref.color;
  return {
    light: {
      bg: "".concat(color, ".500"),
      color: "white"
    },
    dark: {
      bg: "".concat(color, ".200"),
      color: "gray.900"
    }
  };
};

var subtle = function subtle(_ref2) {
  var color = _ref2.color,
      colors = _ref2.theme.colors;
  var darkBg = colors[color][200];
  return {
    light: {
      bg: "".concat(color, ".50")
    },
    dark: {
      bg: colorEmphasis(darkBg, "lowest")
    }
  };
};

var statusStyleProps = function statusStyleProps(props) {
  switch (props.variant) {
    case "solid":
      return solid(props);

    case "subtle":
      return subtle(props);

    case "top-accent":
      return topAccent(props);

    case "left-accent":
      return leftAccent(props);

    default:
      return {};
  }
};

var baseProps = {
  display: "flex",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  pl: 4,
  pr: 4,
  py: 3
};

var useAlertStyle = function useAlertStyle(props) {
  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var theme = useTheme();

  var _props = _extends({}, props, {
    theme: theme
  });

  return _extends({}, baseProps, {}, statusStyleProps(_props)[mode]);
};

export var useIconStyle = function useIconStyle(_ref3) {
  var variant = _ref3.variant,
      color = _ref3.color;

  var _useUIMode2 = useUIMode(),
      mode = _useUIMode2.mode;

  if (["left-accent", "top-accent", "subtle"].includes(variant)) {
    var result = {
      light: {
        color: "".concat(color, ".500")
      },
      dark: {
        color: "".concat(color, ".200")
      }
    };
    return result[mode];
  }
};
export default useAlertStyle;