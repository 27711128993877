import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import propTypes from "prop-types";
import { Children, cloneElement, useState, useRef } from "react";
import { genId } from "../utils";
import Box from "../Box";

var RadioGroup = function RadioGroup(_ref) {
  var id = _ref.id,
      onChange = _ref.onChange,
      name = _ref.name,
      size = _ref.size,
      defaultValue = _ref.defaultValue,
      isInline = _ref.isInline,
      valueProp = _ref.value,
      _ref$spacing = _ref.spacing,
      spacing = _ref$spacing === void 0 ? 4 : _ref$spacing,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["id", "onChange", "name", "size", "defaultValue", "isInline", "value", "spacing", "children"]);

  var _useRef = useRef(valueProp != null),
      isControlled = _useRef.current;

  var _useState = useState(defaultValue || null),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var _value = isControlled ? valueProp : value;

  var _onChange = function _onChange(event) {
    var _val = event.target.value;
    !isControlled && setValue(_val);
    onChange && onChange(event, _val);
  }; // If no name is passed, we'll generate a random, unique name


  var _name = name || genId("radio");

  var clones = Children.map(children, function (child, index) {
    var isLastRadio = children.length === index + 1;
    var spacingProps = isInline ? {
      mr: spacing
    } : {
      mb: spacing
    };
    return jsx(Box, _extends({
      display: isInline ? "inline-block" : "block"
    }, !isLastRadio && spacingProps), cloneElement(child, {
      size: size,
      name: _name,
      onChange: _onChange,
      isChecked: child.props.value === _value
    }));
  });
  return jsx(Box, _extends({
    role: "radiogroup",
    "aria-labelledby": id
  }, rest), clones);
};

process.env.NODE_ENV !== "production" ? RadioGroup.propTypes = {
  /**
   * The selected (controlled) value of the Radio Group.
   */
  value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.object]),

  /**
   * The initial selected value of the Radio Group
   */
  defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.object]),

  /**
   * Function fired when a radio button is selected
   *
   * @param {object} event - The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   *
   * @param {string} value - The `value` of the selected radio button
   * */
  onChange: propTypes.func
} : void 0;
export default RadioGroup;