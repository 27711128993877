import _extends from "@babel/runtime/helpers/extends";

/** @jsx jsx */
import { useTheme, useUIMode } from "../ThemeProvider";
var centeredProps = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)"
};

var thumbStyle = function thumbStyle(_ref) {
  var thumbSize = _ref.thumbSize,
      trackPercent = _ref.trackPercent,
      theme = _ref.theme;
  return _extends({}, centeredProps, {
    zIndex: 1,
    size: thumbSize,
    rounded: "full",
    bg: "#fff",
    boxShadow: "sm",
    left: "calc(".concat(trackPercent, "% - ").concat(thumbSize, " / 2)"),
    border: "1px",
    borderColor: "transparent",
    transition: "transform 0.2s",
    _focus: {
      boxShadow: "outline"
    },
    _disabled: {
      backgroundColor: "gray.300"
    },
    _active: {
      transform: "translateY(-50%) scale(1.15)"
    }
  });
};

var filledTrackStyle = function filledTrackStyle(_ref2) {
  var trackHeight = _ref2.trackHeight,
      trackPercent = _ref2.trackPercent;
  return _extends({}, centeredProps, {
    height: trackHeight,
    bg: "blue.500",
    width: "".concat(trackPercent, "%"),
    rounded: "sm"
  });
};

var themedTrackStyle = {
  light: {
    bg: "gray.200",
    _disabled: {
      bg: "gray.300"
    }
  },
  dark: {
    bg: "whiteAlpha.200",
    _disabled: {
      bg: "whiteAlpha.300"
    }
  }
};

var trackStyle = function trackStyle(_ref3) {
  var trackHeight = _ref3.trackHeight,
      theme = _ref3.theme,
      mode = _ref3.mode;
  return _extends({
    height: trackHeight,
    borderRadius: "sm"
  }, themedTrackStyle[mode]);
};

var rootStyle = {
  width: "full",
  display: "inline-block",
  position: "relative",
  cursor: "pointer",
  _disabled: {
    opacity: 0.6,
    cursor: "default",
    pointerEvents: "none"
  }
};
var sizes = {
  lg: {
    thumb: "16px",
    trackHeight: "4px"
  },
  md: {
    thumb: "14px",
    trackHeight: "4px"
  },
  sm: {
    thumb: "10px",
    trackHeight: "2px"
  }
};

var useSliderStyle = function useSliderStyle(props) {
  var theme = useTheme();

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var trackPercent = props.trackPercent,
      size = props.size,
      color = props.color;
  var _sizes$size = sizes[size],
      trackHeight = _sizes$size.trackHeight,
      thumbSize = _sizes$size.thumb;
  var _props = {
    trackHeight: trackHeight,
    thumbSize: thumbSize,
    theme: theme,
    trackPercent: trackPercent,
    color: color,
    mode: mode
  };
  return {
    rootStyle: rootStyle,
    trackStyle: trackStyle(_props),
    filledTrackStyle: filledTrackStyle(_props),
    thumbStyle: thumbStyle(_props)
  };
};

export default useSliderStyle;