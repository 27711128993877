import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Children, cloneElement, forwardRef, useRef, useState } from "react";
import propTypes from "prop-types";
import Box from "../Box";
import Collapse from "../Collapse";
import PseudoBox from "../PseudoBox";
import { genId } from "../utils";
var Accordion = forwardRef(function (_ref, ref) {
  var isOpen = _ref.isOpen,
      defaultIsOpen = _ref.defaultIsOpen,
      onOpenChange = _ref.onOpenChange,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["isOpen", "defaultIsOpen", "onOpenChange", "children"]);

  var _useState = useState(defaultIsOpen || false),
      _useState2 = _slicedToArray(_useState, 2),
      isExpanded = _useState2[0],
      setIsExpanded = _useState2[1];

  var _useRef = useRef(isOpen != null),
      isControlled = _useRef.current;

  var _isExpanded = isControlled ? isOpen : isExpanded;

  var onToggle = function onToggle() {
    onOpenChange && onOpenChange(!_isExpanded);
    !isControlled && setIsExpanded(!isExpanded);
  };

  var headerId = genId("header");
  var panelId = genId("panel");
  return jsx(Box, _extends({
    ref: ref
  }, rest), Children.map(children, function (child) {
    return cloneElement(child, {
      headerId: headerId,
      panelId: panelId,
      onToggle: onToggle,
      isExpanded: _isExpanded
    });
  }));
});
process.env.NODE_ENV !== "production" ? Accordion.propTypes = {
  isOpen: propTypes.bool,
  defaultIsOpen: propTypes.bool,
  onOpenChange: propTypes.func,
  children: propTypes.node.isRequired
} : void 0; /////////////////////////////////////////////////////////////

var AccordionHeader = forwardRef(function (_ref2, ref) {
  var isExpanded = _ref2.isExpanded,
      isDisabled = _ref2.isDisabled,
      size = _ref2.size,
      panelId = _ref2.panelId,
      headerId = _ref2.headerId,
      onToggle = _ref2.onToggle,
      children = _ref2.children,
      rest = _objectWithoutProperties(_ref2, ["isExpanded", "isDisabled", "size", "panelId", "headerId", "onToggle", "children"]);

  return jsx(PseudoBox, _extends({
    ref: ref,
    display: "flex",
    alignItems: "center",
    width: "100%",
    position: "relative",
    borderRadius: "sm",
    transition: "all 0.2s",
    as: "button",
    disabled: isDisabled,
    "aria-disabled": isDisabled,
    "aria-expanded": isExpanded,
    onClick: onToggle,
    id: headerId,
    "aria-controls": panelId
  }, rest), typeof children === "function" ? children({
    isExpanded: isExpanded,
    isDisabled: isDisabled
  }) : children);
}); /////////////////////////////////////////////////////////////

var AccordionPanel = forwardRef(function (_ref3, ref) {
  var isExpanded = _ref3.isExpanded,
      onToggle = _ref3.onToggle,
      headerId = _ref3.headerId,
      panelId = _ref3.panelId,
      rest = _objectWithoutProperties(_ref3, ["isExpanded", "onToggle", "headerId", "panelId"]);

  return jsx(Collapse, _extends({
    role: "region",
    id: panelId,
    "aria-labelledby": headerId,
    ref: ref,
    "aria-hidden": !isExpanded,
    isOpen: isExpanded
  }, rest));
});
export default Accordion;
export { AccordionHeader, AccordionPanel };