import _extends from "@babel/runtime/helpers/extends";
import { useTheme, useUIMode } from "../ThemeProvider";
export var useMenuListStyle = function useMenuListStyle() {
  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var elevation = {
    light: {
      bg: "#fff",
      boxShadow: "0 7px 14px 0 rgba(0,0,0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)"
    },
    dark: {
      bg: "gray.800",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px"
    }
  };
  return _extends({
    color: "inherit"
  }, elevation[mode]);
};
/**
|--------------------------------------------------
| Styles for MenuItem
|--------------------------------------------------
*/

var baseProps = {
  width: "full",
  flex: " 0 0 auto",
  userSelect: "none",
  transition: "background-color 220ms, color 220ms"
};

var interactionProps = function interactionProps(_ref) {
  var mode = _ref.mode;
  var _focusColor = {
    light: "gray.100",
    dark: "whiteAlpha.100"
  };
  var _activeColor = {
    light: "gray.200",
    dark: "whiteAlpha.200"
  };
  return {
    _active: {
      bg: _activeColor[mode]
    },
    _focus: {
      bg: _focusColor[mode]
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  };
};

export var useMenuItemStyle = function useMenuItemStyle() {
  var theme = useTheme();

  var _useUIMode2 = useUIMode(),
      mode = _useUIMode2.mode;

  var props = {
    theme: theme,
    mode: mode
  };
  return _extends({}, baseProps, {}, interactionProps(props));
};