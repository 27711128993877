import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import styled from "@emotion/styled";
import css from "@styled-system/css";
import Box from "../Box";
import { tx } from "../Box/config";
var ControlBox = styled(Box)(function (_ref) {
  var _css;

  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? "checkbox" : _ref$type,
      _hover = _ref._hover,
      _invalid = _ref._invalid,
      _disabled = _ref._disabled,
      _focus = _ref._focus,
      _checked = _ref._checked,
      _ref$_child = _ref._child,
      _child = _ref$_child === void 0 ? {
    opacity: 0
  } : _ref$_child,
      _ref$_checkedAndChild = _ref._checkedAndChild,
      _checkedAndChild = _ref$_checkedAndChild === void 0 ? {
    opacity: 1
  } : _ref$_checkedAndChild,
      _checkedAndDisabled = _ref._checkedAndDisabled,
      _checkedAndFocus = _ref._checkedAndFocus,
      _checkedAndHover = _ref._checkedAndHover;

  var checkedAndDisabled = "input[type=".concat(type, "]:checked:disabled + &"),
      checkedAndHover = "input[type=".concat(type, "]:checked:hover:not(:disabled) + &"),
      checkedAndFocus = "input[type=".concat(type, "]:checked:focus + &"),
      disabled = "input[type=".concat(type, "]:disabled + &"),
      focus = "input[type=".concat(type, "]:focus + &"),
      hover = "input[type=".concat(type, "]:hover:not(:disabled):not(:checked) + &"),
      checked = "input[type=".concat(type, "]:checked + &"),
      invalid = "input[type=".concat(type, "][aria-invalid=true] + &");
  return css((_css = {}, _defineProperty(_css, focus, tx(_focus)), _defineProperty(_css, hover, tx(_hover)), _defineProperty(_css, disabled, tx(_disabled)), _defineProperty(_css, invalid, tx(_invalid)), _defineProperty(_css, checkedAndDisabled, tx(_checkedAndDisabled)), _defineProperty(_css, checkedAndFocus, tx(_checkedAndFocus)), _defineProperty(_css, checkedAndHover, tx(_checkedAndHover)), _defineProperty(_css, "& > *", tx(_child)), _defineProperty(_css, checked, _extends({}, tx(_checked), {
    "& > *": tx(_checkedAndChild)
  })), _css));
});
ControlBox.defaultProps = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 120ms",
  flexShrink: "0",
  "aria-hidden": "true"
};
export default ControlBox;