import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { forwardRef } from "react";
import propTypes from "prop-types";
import Icon from "../Icon";
import Button from "../Button";
var IconButton = forwardRef(function (_ref, ref) {
  var icon = _ref.icon,
      isRound = _ref.isRound,
      ariaLabel = _ref["aria-label"],
      rest = _objectWithoutProperties(_ref, ["icon", "isRound", "aria-label"]);

  // Remove some props before passing it to IconButton
  var isFullWidth = rest.isFullWidth,
      leftIcon = rest.leftIcon,
      rightIcon = rest.rightIcon,
      loadingText = rest.loadingText,
      props = _objectWithoutProperties(rest, ["isFullWidth", "leftIcon", "rightIcon", "loadingText"]);

  return jsx(Button, _extends({
    css: {
      padding: 0
    },
    borderRadius: isRound ? "round" : "md",
    ref: ref,
    "aria-label": ariaLabel
  }, props), jsx(Icon, {
    name: icon,
    color: "currentColor",
    "aria-hidden": true
  }));
});
IconButton.displayName = "IconButton";
/**
 * You can also pass the other props in [Button](/components/Button)
 * */

process.env.NODE_ENV !== "production" ? IconButton.propTypes = {
  /**
   * The icon to be used. Refer to the [Icons](/components/icons/) section
   * of the docs for the available icon options.
   */
  icon: propTypes.string.isRequired,

  /**
   * If `true`, the button will be perfectly round. Else, it'll be slightly round
   */
  isRound: propTypes.bool,

  /**
   * A11y: A label that describes the button
   */
  "aria-label": propTypes.string.isRequired
} : void 0;
IconButton.defaultProps = Button.defaultProps;
export default IconButton;