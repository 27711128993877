import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import Box from "../Box";

var Divider = function Divider(_ref) {
  var orientation = _ref.orientation,
      props = _objectWithoutProperties(_ref, ["orientation"]);

  return jsx(Box, _extends({
    as: "hr",
    my: "8px",
    role: "separator",
    "aria-orientation": orientation,
    border: "0",
    borderBottom: "1px",
    opacity: "0.6",
    borderColor: "inherit"
  }, props));
};

export default Divider;