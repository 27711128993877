import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { forwardRef } from "react";
import propTypes from "prop-types";
import ControlBox from "../ControlBox";
import { useUIMode } from "../ThemeProvider";
import VisuallyHidden from "../VisuallyHidden";
import checkboxStyles from "../Checkbox/styles";
import Flex from "../Flex";
import Box from "../Box";
var Radio = forwardRef(function (_ref, ref) {
  var id = _ref.id,
      name = _ref.name,
      value = _ref.value,
      ariaLabel = _ref["aria-label"],
      ariaLabelledBy = _ref["aria-labelledby"],
      color = _ref.color,
      defaultChecked = _ref.defaultChecked,
      isChecked = _ref.isChecked,
      isFullWidth = _ref.isFullWidth,
      size = _ref.size,
      isDisabled = _ref.isDisabled,
      isInvalid = _ref.isInvalid,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      onFocus = _ref.onFocus,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["id", "name", "value", "aria-label", "aria-labelledby", "color", "defaultChecked", "isChecked", "isFullWidth", "size", "isDisabled", "isInvalid", "onChange", "onBlur", "onFocus", "children"]);

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var styleProps = checkboxStyles({
    color: color,
    size: size,
    mode: mode
  });
  return jsx(Flex, _extends({
    as: "label",
    display: "inline-flex",
    verticalAlign: "top",
    htmlFor: id,
    alignItems: "center",
    width: isFullWidth ? "full" : undefined,
    cursor: isDisabled ? "not-allowed" : "pointer"
  }, rest), jsx(VisuallyHidden, {
    as: "input",
    type: "radio",
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    id: id,
    ref: ref,
    name: name,
    value: value,
    "aria-invalid": isInvalid,
    defaultChecked: defaultChecked,
    onChange: onChange,
    onBlur: onBlur,
    onFocus: onFocus,
    checked: isChecked,
    disabled: isDisabled
  }), jsx(ControlBox, _extends({}, styleProps, {
    type: "radio",
    rounded: "full"
  }), jsx(Box, {
    bg: "currentColor",
    as: "span",
    rounded: "full",
    size: "50%"
  })), children && jsx(Box, {
    ml: 2,
    fontSize: size,
    userSelect: "none",
    opacity: isDisabled ? 0.32 : 1
  }, children));
});
Radio.defaultProps = {
  size: "md",
  color: "blue"
};
process.env.NODE_ENV !== "production" ? Radio.propTypes = {
  /**
   * The aria-label attribute associated with the radio element
   */
  "aria-label": propTypes.string,

  /**
   * If `true`, the radio will be disabled
   */
  isDisabled: propTypes.bool,

  /**
   * If `true`, the radio is marked as invalid.
   * Changes style of unchecked state.
   */
  isInvalid: propTypes.bool,

  /**
   * The color of the radio when it's checked.
   * This should be one of the color keys in the theme (e.g."green", "red")
   */
  color: propTypes.string,

  /**
   * If `true`, the radio will be initially checked.
   */
  defaultChecked: propTypes.bool,

  /**
   * If `true`, the radio will be checked.
   * You'll need to pass `onChange` to update it's value (since it's now controlled)
   */
  isChecked: propTypes.bool,

  /**
   * id assigned to input
   */
  id: propTypes.string,

  /**
   * The name of the input field in a radio
   * (Useful for form submission).
   */
  name: propTypes.string,

  /**
   * The value to be used in the radio button.
   * This is the value that will be returned on form submission.
   */
  value: propTypes.oneOfType([propTypes.string, propTypes.number])
} : void 0;
export default Radio;