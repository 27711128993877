import _extends from "@babel/runtime/helpers/extends";
import { useTheme, useUIMode } from "../ThemeProvider";
import { isDarkColor } from "../theme/colors.utils"; // Found this on StackOverflow :)

function string2Hex(str) {
  var hash = 0;
  if (str.length === 0) return hash;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  var color = "#";

  for (var j = 0; j < 3; j++) {
    var value = hash >> j * 8 & 255;
    color += ("00" + value.toString(16)).substr(-2);
  }

  return color;
}

export var avatarSizes = {
  xs: 5,
  sm: 6,
  md: 10,
  lg: 12,
  xl: 16,
  "2xl": 24
};

var useAvatarStyle = function useAvatarStyle(props) {
  var _useTheme = useTheme(),
      colors = _useTheme.colors;

  var _useUIMode = useUIMode(),
      mode = _useUIMode.mode;

  var size = props.size,
      name = props.name,
      showBorder = props.showBorder;
  var bg = name ? string2Hex(name) : colors.gray[400];
  var color = name ? isDarkColor(bg) ? "#fff" : "gray.800" : "#fff";
  var borderColor = {
    light: "#fff",
    dark: "gray.900"
  };
  var baseProps = {
    display: "inline-flex",
    rounded: "full",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  };
  return _extends({}, baseProps, {
    size: avatarSizes[size],
    bg: bg,
    color: color
  }, showBorder && {
    border: "2px",
    borderColor: borderColor[mode]
  });
};

export default useAvatarStyle;